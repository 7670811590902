import {RemixBrowser} from '@remix-run/react';
import ShopifyFormat from '@shopify/i18next-shopify';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import {startTransition, StrictMode} from 'react';
import {hydrateRoot} from 'react-dom/client';
import {I18nextProvider, initReactI18next} from 'react-i18next';
import {getInitialNamespaces} from 'remix-i18next/client';
import i18nextOptions from './utils/i18nextOptions';
import {
  loadLocalePolyfills,
  loadPluralRulesPolyfills,
} from './utils/intlPolyfills';

function hydrate() {
  startTransition(() => {
    hydrateRoot(
      document,
      <StrictMode>
        <I18nextProvider i18n={i18next}>
          <RemixBrowser />
        </I18nextProvider>
      </StrictMode>,
    );
  });
}

async function initI18n() {
  await loadLocalePolyfills();
  await i18next
    .use(initReactI18next)
    .use(ShopifyFormat)
    .use(LanguageDetector)
    .use(
      resourcesToBackend(
        (locale: string, namespace: string) =>
          import(`./locales/${locale}/${namespace}.json`),
      ),
    )
    .init({
      ...i18nextOptions,
      react: {useSuspense: false},
      ns: getInitialNamespaces(),
      detection: {
        order: ['htmlTag'],
        caches: [],
      },
    });
  await loadPluralRulesPolyfills(i18nextOptions.fallbackLng, i18next.language);
}

initI18n().then(hydrate);
