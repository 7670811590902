import type {RemixI18NextOption} from 'remix-i18next/server';

/**
 * The supported locales for the app.
 *
 * These should correspond with the JSON files in the `public/locales` folder.
 *
 * @see Available Shopify Admin languages in the Shopify Help Center:
 * https://help.shopify.com/en/manual/your-account/languages#available-languages
 */
const supportedLngs = [
  'cs',
  'da',
  'de',
  'en',
  'es',
  'fi',
  'fr',
  'it',
  'ja',
  'ko',
  'nb',
  'nl',
  'pl',
  'pt',
  'pt-BR',
  'pt-PT',
  'sv',
  'th',
  'tr',
  'vi',
  'zh',
  'zh-CN',
  'zh-TW',
];
const options = {
  /**
   * Set to `process.env.NODE_ENV !== "production"` to see debug output
   */
  debug: false,
  /**
   * The default locale for the app.
   */
  fallbackLng: 'en',

  /**
   * The supported locales for the app.
   *
   * These should correspond with the JSON files in the `./app/locales` folder.
   *
   * @see Available Shopify Admin languages in the Shopify Help Center:
   * https://help.shopify.com/en/manual/your-account/languages#available-languages
   */
  supportedLngs,
  nonExplicitSupportedLngs: true,
} as const satisfies RemixI18NextOption['i18next'];

export default options;

export type SupportedLocale = (typeof supportedLngs)[number];
